
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import ContactUs from '@/components/contactUs/index.vue'
export default {
    name: 'webstore',
    components:{
        Header,
        Footer,
        ContactUs
    },
    mounted () {
    },
    created(){

    },
    methods: {
        opus(){
            window.open('https://gongxinyi.renshihr.com', '_blank');
        }
    },

    data(){
        return{


        }
        
    }
    
}